import styled, { keyframes } from 'styled-components'

export const anchorUpEnter = keyframes`
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100vh;
  }
`

export const anchorUpExit = keyframes`
  0% {
    opacity: 1;
    height: 100vh;
  }

  100% {
    opacity: 0;
    height: 0;
  }
`

export const anchorLeftEnter = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100vw, 0, 0);
    transform: translate3d(-100vw, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

export const anchorRightEnter = keyframes`
  0% {
    -webkit-transform: translate3d(100vw, 0, 0);
    transform: translate3d(100vw, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

export const anchorLeftExit = keyframes`
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-100vw, 0, 0);
    transform: translate3d(-100vw, 0, 0);
  }
`

export const anchorRightExit = keyframes`
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(100vw, 0, 0);
    transform: translate3d(100vw, 0, 0);
  }
`

export const mobileMenuEnterRecord = {
  0: anchorUpEnter,
  1: anchorRightEnter,
  2: anchorRightEnter,
}

export const mobileMenuExitRecord = {
  0: anchorUpExit,
  1: anchorRightExit,
  2: anchorRightExit,
}

const getAnimationAnchorType = (isGlobalOpen, isOpen, level) => {
  if ( !isGlobalOpen ) {
    return anchorUpExit
  }
  
  return isOpen ? mobileMenuEnterRecord[level] : mobileMenuExitRecord[level]
}


export const StyledMenuNavigationContainer = styled.div<{ $isGlobalOpen: boolean; $isOpen: boolean; $level: number; $animationTime: number }>`
  display: block;
  position: fixed;
  top: ${props => (props.$level == 0) ? 0 : props.theme.typography.pxToRem(66)};
  left: 0;
  right: 0;
  height: 100vh;
  background: ${props => props.theme.palette.background.default};
  z-index: ${props => props.$level === 0 ? props.theme.zIndex.appBar + 1 : (props.theme.zIndex.appBar + 1) * props.$level  * 1000};
  animation: ${props => getAnimationAnchorType(props.$isGlobalOpen, props.$isOpen, props.$level)} ${props => props.$animationTime}ms ease-in-out;
`